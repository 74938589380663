.containerSequenceSlot {
    min-height: 2rem;
    max-height: 2rem;

    min-width: fit-content(8rem);
    max-width: fit-content(8rem);

    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-radius: 0.4rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #d5bd73;
    color: #1F1815;
}
.containerSequenceSlot.selected {
    background-color: #df6652;
}

.subsectionText {
    padding-left: 0.5rem;

    color: #f9f2ef;

    font-weight: 500;
}

.bodyText {
    color: #f9f2ef;

    text-align: center;
}

.countdowntimerContainer {
    min-height: 2rem;
    max-height: 2rem;

    min-width: fit-content(8rem);
    max-width: fit-content(8rem);

    padding-left: 1rem;
    padding-right: 1rem;

    filter: drop-shadow(0.1rem 0.1rem 0.1rem #44444466);

    border-radius: 0.4rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #f9f2ef;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
