.containerContent {
    width: 100vw;
    /* height: 100vh; */

    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding: 1rem;
}

.containerTopContent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.containerTopLeft {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.4rem;
}

.containerTopLeftColumn {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.containerBottomContent {
    display: flex;
    flex-direction: row;
}
