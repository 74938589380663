.containerPomodoro {
    display: flex;
    flex-direction: column;

    overflow: hidden;

    background-color: #1f1815aa;
}

.noTimerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0.4rem;
    margin: 0px;    
}

.noTimerTitle {
    font-size: 10vw;
    font-weight: 900;
    color: #f9f2ef;

    font-family: 'Courier New', Courier, monospace;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mainTimerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 2rem;
    margin: 0px;
}

.mainTimerTitle {
    font-size: 12vw;
    font-weight: 900;
    color: #f9f2ef;

    font-family: 'Courier New', Courier, monospace;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mainTimerLabel {
    font-size: 20vw;
    font-weight: 900;
    color: #f9f2ef;

    font-family: 'Courier New', Courier, monospace;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex-grow: 1;
}
