.containerDiscord {
    display: flex;
    flex-direction: column;
    gap: 0px;

    overflow: hidden;
}

.containerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.4rem;
}

.discordLogo {
    font-size: 2rem;
    line-height: 2rem;
    color: #f9f2ef;
}

.title {
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 700;
    color: #f9f2ef;
}

.widgetHeaderFooterContainer {
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.widgetHeaderMiddleContainer {
    flex-grow: 1;

    border-top: var(--pixelSize) solid var(--colorWidgetBorder);

    max-height: calc(var(--pixelSize) * 2);
    min-height: calc(var(--pixelSize) * 2);

    background-color: var(--colorWidgetBackground);
}

.widgetFooterMiddleContainer {
    flex-grow: 1;

    border-bottom: var(--pixelSize) solid var(--colorWidgetBorder);

    max-height: calc(var(--pixelSize) * 2);
    min-height: calc(var(--pixelSize) * 2);

    background-color: var(--colorWidgetBackground);
}

.containerChannelMembers {
    --avatar-size: 76px;
    --avatarsPerRow: 6;

    max-width: calc(var(--avatarsPerRow) * (var(--avatar-size) + var(--pixelSize)) + (4 * var(--pixelSize)));

    display: flex;
    flex-direction: row;

    column-gap: var(--pixelSize);
    row-gap: var(--pixelSize);

    flex-wrap: wrap;

    background-color: var(--colorWidgetBackground);
    border-left: var(--pixelSize) solid var(--colorWidgetBorder);
    border-right: var(--pixelSize) solid var(--colorWidgetBorder);

    padding-left: var(--pixelSize);
    padding-right: var(--pixelSize);
}

.containerChannelMember {
    min-height: var(--avatar-size);
    max-height: var(--avatar-size);

    min-width: var(--avatar-size);
    max-width: var(--avatar-size);

    position: relative;
}

.containerChannelMemberForeground {
    min-height: var(--avatar-size);
    max-height: var(--avatar-size);

    min-width: var(--avatar-size);
    max-width: var(--avatar-size);

    position: absolute;
    top: 0px;

    display: flex;
    flex-direction: column;
    gap: 0px;
}

.avatarHeaderFooterContainer {
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.avatarHeaderMiddleContainer {
    flex-grow: 1;

    border-top: var(--pixelSize) solid var(--colorAvatarBorder);

    max-height: calc(var(--pixelSize) * 2);
    min-height: calc(var(--pixelSize) * 2);
}

.avatarFooterMiddleContainer {
    flex-grow: 1;

    border-bottom: var(--pixelSize) solid var(--colorAvatarBorder);

    max-height: calc(var(--pixelSize) * 2);
    min-height: calc(var(--pixelSize) * 2);
}

.avatarMiddleContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    overflow: hidden !important;

    border-left: var(--pixelSize) solid var(--colorAvatarBorder);
    border-right: var(--pixelSize) solid var(--colorAvatarBorder);
}

.channelMemberAvatar {
    --avatarImageSize: calc(var(--avatar-size) - (2*var(--pixelSize)));

    min-height: var(--avatarImageSize);
    max-height: var(--avatarImageSize);

    min-width: var(--avatarImageSize);
    max-width: var(--avatarImageSize);

    position: absolute;
    top: var(--pixelSize);
    left: var(--pixelSize);
}