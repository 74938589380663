.containerSequenceSlot {
    min-height: 2rem;
    max-height: 2rem;

    min-width: fit-content(8rem);
    max-width: fit-content(8rem);

    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-radius: 0.4rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #d5bd73;
    color: #1F1815;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.containerSequenceItem {
    margin-bottom: 0.6rem;
}