.containerContent {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    overflow: hidden !important;

    /* background-color: red; */
}

.containerTopCenterContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerTopContent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    overflow: hidden !important;

    /* background-color: green; */
}

.containerTopLeft {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: start;
    gap: 0.4rem;

    overflow: hidden !important;

    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;

    /* background-color: green; */
}

.containerTopRight {
    height: 100% !important;
    max-height: 100% !important;

    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.4rem;

    overflow: hidden !important;

    /* background-color: yellow; */
}

.containerTopLeftColumn {
    height: 100% !important;
    max-height: 100% !important;

    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    overflow: hidden !important;

    /* background-color: pink; */
}

.containerBottomContent {
    display: flex;
    flex-direction: row;

    /* background-color: brown; */
}

.containerBottomLeft {
    display: flex;
    flex-direction: column;

    padding-bottom: 1rem;
    padding-left: 1rem;
}

.containerBottomRight {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.pixelColumn {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.pixelRow {
    display: flex;
    flex-direction: row;
    gap: 0px;
}
