.containerPomodoro {
    position: relative;

    min-width: var(--dimen-widget-width);
    min-height: var(--dimen-widget-height);

    /* background-color: red; */
}

.pomodoroBarContainer {
    position: absolute;
    bottom: 30px;
    left: 0px;
    right: 0px;
}

.pomodoroBarFirstLayer {
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
}

.pomodoroBarSecondLayer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.pomodoroBarThirdLayer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.pomodoroTimerContainer {
    position: absolute;
    bottom: 56px;
    left: 210px;
    right: 30px;

    overflow: hidden;

    /* background-color: brown; */
}

.pomodoroOrbContainer {
    /* opacity: 0%; */

    position: absolute;
    top: 0px;
    left: 20px;

    /* background-color: green; */
}

.pomodoroOrbLabelOuterContainer {
    /* opacity: 0%; */

    position: absolute;
    bottom: -20px;
    left: 0px;
    right: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.pomodoroHeaderFooterContainer {
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.pomodoroHeaderMiddleContainer {
    flex-grow: 1;

    border-top: var(--pixelSize) solid var(--color-orb-label-border);

    max-height: calc(var(--pixelSize) * 2);
    min-height: calc(var(--pixelSize) * 2);

    background-color: var(--color-orb-label-background);
}

.pomodoroFooterMiddleContainer {
    flex-grow: 1;

    border-bottom: var(--pixelSize) solid var(--color-orb-label-border);

    max-height: calc(var(--pixelSize) * 2);
    min-height: calc(var(--pixelSize) * 2);

    background-color: var(--color-orb-label-background);
}

.pomodoroInnerContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    overflow: hidden !important;

    border-left: var(--pixelSize) solid var(--color-orb-label-border);
    border-right: var(--pixelSize) solid var(--color-orb-label-border);
    background-color: var(--color-orb-label-background);
}

.noTimerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0.4rem;
    margin: 0px;    
}

.noTimerTitle {
    font-family: 'Pixel';
    font-size: 14pt;
    font-weight: 600;
    line-height: 0pt;
    color: var(--color-orb-label-font);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mainTimerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0.4rem;
    margin: 0px;    
}

.mainTimerTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'Pixel';
    font-size: 14pt;
    font-weight: 600;
    line-height: 0pt;
    color: var(--color-orb-label-font);
}

.mainTimerLabel {
    width: 100%;

    font-family: 'Pixel';
    font-size: 40pt;
    text-align: center;
    text-shadow: 0px 0px 9px #1f1815;
    
    color: var(--color-orb-timer-font);

    transform: scale(1, 1.5);
}
