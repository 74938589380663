#containerTasks {
    --color-font: #1f1815;
    --color-border: #1f1815;
    --color-background: #f9f2ef;
    --tasks-spacing: 0.4rem;
    
    width: 36rem;
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    overflow: hidden !important;
}

.tasksHeaderFooterContainer {
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.tasksHeaderMiddleContainer {
    flex-grow: 1;

    border-top: var(--pixelSize) solid var(--color-border);

    max-height: calc(var(--pixelSize) * 3);
    min-height: calc(var(--pixelSize) * 3);

    background-color: var(--color-background);
}

.tasksFooterMiddleContainer {
    flex-grow: 1;

    border-bottom: var(--pixelSize) solid var(--color-border);

    max-height: calc(var(--pixelSize) * 3);
    min-height: calc(var(--pixelSize) * 3);

    background-color: var(--color-background);
}

.tasksSeparator {
    flex-grow: 1;

    border-bottom: var(--pixelSize) solid var(--color-border);

    max-height: calc(var(--pixelSize));
    min-height: calc(var(--pixelSize));

    margin-top: calc(var(--pixelSize)*2);
    margin-bottom: calc(var(--pixelSize)*2);
}

#tasksContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    overflow: hidden !important;

    border-left: var(--pixelSize) solid var(--color-border);
    border-right: var(--pixelSize) solid var(--color-border);
    background-color: var(--color-background);

    padding: 0px calc(var(--pixelSize) * 3);
}

#tasksHeaderContainer {
    display: flex;
    flex-direction: row;
}

#tasksHeaderText {
    font-family: 'Pixel';
    font-size: 1.2rem;
}

#tasksHeaderSpacer {
    flex-grow: 1;
}

#tasksInnerContainer {
    display: flex;
    flex-grow: 1;
    
    overflow: hidden !important;
}

#tasksInnerContainer.scrolling {
    -webkit-mask: linear-gradient(transparent 0%, black 3%, black 97%, transparent 100%);
    mask: linear-gradient(transparent 0%, black 3%, black 97%, transparent 100%);
}

#tasksContent {
    display: flex;
    flex-direction: column;
    row-gap: var(--tasks-spacing);

    animation: scrollloop var(--duration) linear infinite backwards;
}

#tasksBaseContent {
    display: flex;
    flex-direction: column;
    row-gap: var(--tasks-spacing);
}

.taskItemContainer {
    display: flex;
    flex-direction: row;
    column-gap: 0.8rem;

    font-family: 'Pixel';
    font-size: 0.8rem;
}

.taskItemUsernameText {
    font-weight: 600;
    color: var(--color) !important;
}

.taskItemMessageText {
    color: var(--color-font);
}

@keyframes scrollloop {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(var(--transformPercentage));
    }
}