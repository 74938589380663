.pixelColumn {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.pixelRow {
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.statusTextOuterContainer {
    --color-border: #1f1815;
    --color-background: #f9f2ef;

    display: flex;
    flex-direction: column;
    gap: 0px;
}

.statusHeaderFooterContainer {
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.statusHeaderMiddleContainer {
    flex-grow: 1;

    border-top: var(--pixelSize) solid var(--color-border);

    max-height: calc(var(--pixelSize) * 3);
    min-height: calc(var(--pixelSize) * 3);

    background-color: var(--color-background);
}

.statusFooterMiddleContainer {
    flex-grow: 1;

    border-bottom: var(--pixelSize) solid var(--color-border);

    max-height: calc(var(--pixelSize) * 3);
    min-height: calc(var(--pixelSize) * 3);

    background-color: var(--color-background);
}

.statusTextContainer {
    background-color: #f9f2ef;

    font-family: 'Pixel';
    font-size: 1.6rem;

    padding: 1rem 1rem;

    border-left: var(--pixelSize) solid #1f1815;
    border-right: var(--pixelSize) solid #1f1815;
}
