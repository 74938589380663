.containerStatuses {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    align-items: end;
    
    margin-top: 1rem;
    margin-right: 1rem;
}

.containerTopStatuses {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
}
