.container {
    min-height: 2rem;
    max-height: 2rem;

    min-width: fit-content(8rem);
    max-width: fit-content(8rem);

    padding-left: 1rem;
    padding-right: 1rem;

    filter: drop-shadow(0.1rem 0.1rem 0.1rem #44444466);

    border-radius: 0.4rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.container:hover {
    cursor: pointer;
}

.container.disabled {
    opacity: 0.3;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.5rem;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.iconContainer > * {
    font-size: 1.5em;

    margin-left: -0.5rem;
    margin-right: -0.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}

/* CONFIGURATION FOR TYPES AND EFFECTS */

/* PRIMARY */

.container.primary .containerContent {
    color: #1F1815;
}

.container.primary.positive {
    background-color: #56936b;
}
.container:hover.primary.positive {
    background-color: #46835b;
}
.container:active.primary.positive {
    background-color: #36734b;
}

.container.primary.destructive {
    background-color: #982A1A;
}
.container:hover.primary.destructive {
    background-color: #881A0A;
}
.container:active.primary.destructive {
    background-color: #780A00;
}

/* SECONDARY */

.container.secondary {
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
}

.container.secondary.content {
    color: #ffffff;
    border-color: #ffffff;
}
.container:hover.secondary.content {
    color: #eeeeee;
    border-color: #eeeeee;
}
.container:active.secondary.content {
    color: #dddddd;
    border-color: #dddddd;
}

.container.secondary.positive {
    color: #0071b4;
    border-color: #0071b4;
}
.container:hover.secondary.positive {
    color: #0061a4;
    border-color: #0061a4;
}
.container:active.secondary.positive {
    color: #005194;
    border-color: #005194;
}

.container.secondary.destructive {
    color: #910000;
    border-color: #910000;
}
.container:hover.secondary.destructive {
    color: #810000;
    border-color: #810000;
}
.container:active.secondary.destructive {
    color: #710000;
    border-color: #710000;
}
