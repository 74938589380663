.containerStatuses {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    overflow: hidden;
}

.containerStatusesTop {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    gap: 1.6rem;

    overflow: hidden;

    background-color: #1f1815aa;
    
    padding: 1.6rem 0.4rem;
}

.statusOnAir {
    flex-grow: 1;
}

.containerStatusesBottom {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    flex-wrap: wrap;

    overflow: hidden;

    background-color: #1f1815aa;
    
    padding: 1.6rem 0.4rem;
}

.statusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    margin: 0px;

    padding: 0rem 1rem;
}

.statusIcon {
    font-size: 20vw;
    font-weight: 600;
    color: #f9f2ef;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.statusTitle {
    font-size: 10vw;
    font-weight: 900;
    color: #f9f2ef;

    font-family: 'Courier New', Courier, monospace;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.statusImportant {
    color: #982A1A !important;
}