#containerChat {
    --color-font: #1f1815;

    height: 20rem;
    width: 36rem;
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    overflow: hidden !important;
}

.chatHeaderFooterContainer {
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.chatHeaderMiddleContainer {
    flex-grow: 1;

    border-top: var(--pixelSize) solid var(--color-border);

    max-height: calc(var(--pixelSize) * 3);
    min-height: calc(var(--pixelSize) * 3);

    background-color: var(--color-background);
}

.chatFooterMiddleContainer {
    flex-grow: 1;

    border-bottom: var(--pixelSize) solid var(--color-border);

    max-height: calc(var(--pixelSize) * 3);
    min-height: calc(var(--pixelSize) * 3);

    background-color: var(--color-background);
}

#chatContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    overflow: hidden !important;

    border-left: var(--pixelSize) solid var(--color-border);
    border-right: var(--pixelSize) solid var(--color-border);
    background-color: var(--color-background);


    padding: 0px calc(var(--pixelSize) * 3);
}

#chatInnerContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;

    overflow: hidden !important;
}

.chatItemContainer {
    font-family: 'Pixel';
    font-size: 0.8rem;
}

.chatItemUsernameText {
    font-weight: 600;
    color: var(--color) !important;
}

.chatItemMessageText {
    color: var(--color-font);
}
