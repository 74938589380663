#statusActionsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 1rem;
}

.visibilityToggleItemContainer {
    min-height: 4rem;
    max-height: 4rem;
    min-width: 4rem;
    max-width: 4rem;

    overflow: hidden;
}

.visibilityToggleItemImage {
    min-height: 4rem;
    max-height: 4rem;
    min-width: 4rem;
    max-width: 4rem;
}
.visibilityToggleItemContainer:hover {
    opacity: 80%;
}
.visibilityToggleItemContainer:active {
    opacity: 60%;
}
.visibilityToggleItemImage.flagIsOff {
    filter: contrast(0);
}