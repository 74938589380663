.containerExercise {
    width: 24rem;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    border-radius: 2.4rem;
    border: 0.1rem solid #f9f2ef;
    overflow: hidden;

    background-color: #1f1815ee;

    padding: 1rem 2rem;
}

.exerciseTitleContainer {
    display: flex;
    flex-direction: column;
}

.exerciseTitle {
    font-size: 32pt;
    font-weight: 600;
    color: #f9f2ef;

    font-family: 'Courier New', Courier, monospace;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.exerciseCountersContainer {
    display: flex;
    flex-direction: column;
}

.exerciseCounterItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.exerciseCounterItemName {
    font-size: 22pt;
    font-weight: 600;
    color: #f9f2ef;

    font-family: 'Courier New', Courier, monospace;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.exerciseCounterItemValue {
    font-size: 26pt;
    font-weight: 600;
    color: #f9f2ef;

    font-family: 'Courier New', Courier, monospace;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
