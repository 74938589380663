.container {
  background-color: #1F1815 !important;

  height: 100vh;
  width: 100vw;

  overflow-x: hidden;
}

/* SECTION */

.sectionContainer {
  margin-bottom: 2rem;
}

/* SECTION HEADER */

.sectionHeader {
  min-height: 2rem;
  max-height: 2rem;

  padding-left: 0.5rem;

  color: #1F1815;
  background-color: #f9f2ef;
  font-weight: 600;

  margin-bottom: 0.4rem;

  display: flex;
  align-items: center;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.subsectionHeader {
  min-height: 2rem;
  max-height: 2rem;

  padding-left: 0.5rem;

  color: #f9f2ef;
  font-weight: 600;

  border-bottom: 2px solid #f9f2ef;

  margin-top: 1rem;
  margin-bottom: 0.4rem;

  display: flex;
  align-items: center;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.subsectionActionsContainer {
  margin-top: 1rem !important;
  margin-bottom: 0.4rem !important;
}